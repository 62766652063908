import { generateMapByOpts, mapHelper } from 'common/utils'

// 巡查类型
export const type = [
  {
    text: '项目巡查',
    value: 1
  },
  {
    text: '装修巡查',
    value: 3
  },
  {
    text: '空关房巡查',
    value: 2
  },
  {
    text: '空置房巡查',
    value: 4
  },
  {
    text: '未领房巡查',
    value: 5
  },
]
const {
  map: typeMap,
  setOps: typeOps
} = mapHelper.setMap(type)

// 正常状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 2
  }
]

// 巡查频次
const periodOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '日',
    value: 1
  },
  {
    text: '周',
    value: 3
  },
  {
    text: '月',
    value: 2
  },
  {
    text: '季',
    value: 4
  }
]

// 巡查执行情况页面 巡查状态
const inspectStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待巡查',
    value: 1
  },
  {
    text: '已巡查',
    value: 2
  },
  {
    text: '作废',
    value: 3
  },
  {
    text: '超时未巡查',
    value: 4
  }
]

// 巡查执行情况页面 巡查反馈
const inspectResultOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待查',
    value: 0
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '异常',
    value: 2
  }
]

// 巡查点管理页面 巡查方式
const patternOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '二维码',
    value: 2
  }
]

// 巡查频次
const periodFormOps = [
  {
    text: '日',
    value: 1
  },
  {
    text: '月',
    value: 2
  },
  {
    text: '周',
    value: 3
  },
  {
    text: '季',
    value: 4
  }
]
// 巡查间隔
const timespanUnitFormOps = [
  {
    text: '小时',
    value: 1
  },
  {
    text: '日',
    value: 2
  }
]
// 巡查方式
const patternFormOps = [
  {
    text: '二维码',
    value: 2
  },
  {
    text: '蓝牙',
    value: 1
  }
]

// 巡查分类  分类级别
const levelOps = [
  {
    text: '一级',
    value: 1
  },
  {
    text: '二级',
    value: 2
  }
]

// 巡查分类 巡查间隔类型
const timespanOps = [
  {
    text: '小时',
    value: 1
  },
  {
    text: '日',
    value: 2
  }
]

const statusMap = generateMapByOpts(statusOps)
const periodMap = generateMapByOpts(periodOps)
const inspectStatusMap = generateMapByOpts(inspectStatusOps)
const inspectResultMap = generateMapByOpts(inspectResultOps)
const patternMap = generateMapByOpts(patternOps)
const levelMap = generateMapByOpts(levelOps)
const timespanUnitFormMap = generateMapByOpts(timespanUnitFormOps)
// 角色类型
const userType = [
  {
    text: '系统',
    value: '106'
  },
  {
    text: '集团',
    value: '100'
  },
  {
    text: '区域',
    value: '102'
  },
  {
    text: '项目',
    value: '101'
  },
  {
    text: '商家',
    value: '104'
  },
  {
    text: '门店',
    value: '105'
  }
]
const { map: userTypeMap, setOps: userTypeOps } = mapHelper.setMap(userType)
// 订单状态
const orderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '处理中',
    value: 2
  },
  {
    text: '已处理',
    value: 3
  },
  {
    text: '已评价',
    value: 4
  },
  {
    text: '已关闭',
    value: 5
  },
  {
    text: '待处理',
    value: 6
  }
]

// 新订单状态
const newOrderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  },
]
const orderStatusMap = generateMapByOpts(orderStatusOps)
const newOrderStatusMap = generateMapByOpts(newOrderStatusOps)

export const inspectFlagOptions = [
  {
    text: '是',
    value: 1
  },{
    text: '否',
    value: 2
  }
]

// 项目状态
const communityStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
},
{
  text: '作废',
  value: 2
}
]

const {
map: communityStatusMap,
setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)

const updateFlagOps = [
  {
    text: '巡查频次',
    value:1
  },
  {
    text: '其他',
    value:2
  }
]

export {
  typeOps,
  typeMap,
  statusOps,
  statusMap,
  periodOps,
  periodMap,
  inspectStatusOps,
  inspectStatusMap,
  inspectResultOps,
  inspectResultMap,
  patternOps,
  patternMap,
  periodFormOps,
  timespanUnitFormOps,
  patternFormOps,
  levelOps,
  levelMap,
  timespanOps,
  userTypeMap,
  userTypeOps,
  timespanUnitFormMap,
  orderStatusMap,
  newOrderStatusMap,
  communityStatusMap,
  setCommunityStatusOps,
  updateFlagOps
}
