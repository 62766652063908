/** 通用接口 **/
// 获取一级分类列表
const getCategoryFirstURL = `${API_CONFIG.butlerBaseURL}inspect/category/first/all`
// 获取一级分类列表
const getCategorySecondURL = `${API_CONFIG.butlerBaseURL}inspect/category/second`
// 获取所有分类列表
const getInspectCategoryAllURL = `${API_CONFIG.butlerBaseURL}inspect/category`
// 根据分类获取巡查要点列表
const getInspectCategoryContentURL = `${API_CONFIG.butlerBaseURL}inspect/category/content`

/** 巡查通用内容模块 **/
// 获取巡查通用内容列表
const getContentListURL = `${API_CONFIG.butlerBaseURL}inspect/content/list`
// 巡查通用内容列表--巡查内容的开启/关闭
const handleInspectContentOpenCloseURL = `${API_CONFIG.butlerBaseURL}inspect/content/close`
// 新增/巡查编辑通用内容
const submitInspectContentURL = `${API_CONFIG.butlerBaseURL}inspect/content`

/** 查点分类模块 **/
// 获取巡查点分类列表
const getCategoryListURL = `${API_CONFIG.butlerBaseURL}inspect/category/list`
// 获取巡查点分类详情
const getCategoryDetailURL = `${API_CONFIG.butlerBaseURL}inspect/category/info`
// 巡查分类删除/新增/编辑
const handleInspectCategoryURL = `${API_CONFIG.butlerBaseURL}inspect/category`

/** 巡查执行情况模块 **/
// 获取巡查执行情况列表
const getTaskListURL = `${API_CONFIG.butlerBaseURL}inspect/task/list`
// 获取巡查执行情况详情
const getTaskDetailURL = `${API_CONFIG.butlerBaseURL}inspect/detail`
// 巡查任务作废
const handleInspectTaskCancelURL = `${API_CONFIG.butlerBaseURL}inspect/task/deleteTask`
// 导出巡查执行情况列表
const exportTaskListURL = `${API_CONFIG.butlerBaseURL}inspect/export/task`

// 巡查任务全部作废
const allDeleteTaskCancelURL = `${API_CONFIG.butlerBaseURL}inspect/task/invalidAll`

/** 巡查点管理模块 **/
// 获取巡查点管理列表
const getPointListURL = `${API_CONFIG.butlerBaseURL}inspect/point/all`
// 导出巡查点列表
const exportPointListURL = `${API_CONFIG.butlerBaseURL}inspect/export/point`
// 导出巡查点二维码列表
const exportPointQRCodeListURL = `${API_CONFIG.butlerBaseURL}inspect/export/point/qrcode`
// 批量开启关闭巡查点
const batchInspectPointOnOffURL = `${API_CONFIG.butlerBaseURL}inspect/point/status`
// 开启关闭巡查点
const handleInspectPointOnOffURL = `${API_CONFIG.butlerBaseURL}inspect/point`
// 生成巡查点二维码接口
const getInspectPointQRCodeURL = `${API_CONFIG.butlerBaseURL}inspect/code`
// 导入巡查点
const importMetersURL = `${API_CONFIG.butlerBaseURL}inspect/import`
// 巡查点获取组团
// const getBlockNameUrl = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`
// 获取房号Url
const getRoomUrl = `${API_CONFIG.butlerBaseURL}communityRoom/simple/room/info`
// 获取巡查分类
const getCategoryIdUrl = `${API_CONFIG.butlerBaseURL}inspect/category`
// 获取蓝牙编号
const getBluetoothIdUrl = `${API_CONFIG.baseURL}smDeviceAction!queryBlueteeth.action`
// 获取巡查人员列表
const getPatrolUserHouseListURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/getPatrolUserList`
// 房号多选地址
const houseMultiSelectURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/getCommunityRoomNumModelList`
// multi-select 物管人员列表
const getReceiveUserListURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`
// 发布人归属
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganize.action`
// 角色类型
const getRoleNameListURL = `${API_CONFIG.baseURL}serverUserAction!getRoles.action`
// 获取角色
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 新增巡查人
const savePatrolUserURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/savePatrolUser`
// 删除巡查人
const deletePatrolUserURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/deletePatrolUser`
// 获取用户的巡检房间
const getUserPatrolHouseListURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/getUserPatrolHouseList`
// 更新巡查房号
const updatePatrolUserHouseURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/updatePatrolUserHouse`
// 获取项目下的房号信息
const getCommunityHouseInfoURL = `${API_CONFIG.butlerBaseURL}electronicPatrolUserHouse/getCommunityHouseInfo`
// 获取巡查点配置
const getInspectPointConfigURL = `${API_CONFIG.butlerBaseURL}inspect/getInspectPointConfig`
// 保存巡查点配置
const saveInspectPointConfigURL = `${API_CONFIG.butlerBaseURL}inspect/saveInspectPointConfig`
// 根据园区id获取巡查点配置
const getInspectPointConfigByCommunityIdURL = `${API_CONFIG.butlerBaseURL}inspect/getInspectPointConfigByCommunityId`

// 根据项目获取巡查点数量
const getPointInfoUrl = `${API_CONFIG.butlerBaseURL}inspect/getPointInfo`
// 根据项目id、用户id查询所关联的巡查点信息
const getPatrolPointListUrl = `${API_CONFIG.butlerBaseURL}inspect/getPatrolPointList`
// 巡查人员配置-更新巡查点
const updatePatrolPointUrl = `${API_CONFIG.butlerBaseURL}inspect/updatePatrolPoint`
// 获取当前巡查点中有哪些点被其它用户绑定
const getBoundPointListUrl = `${API_CONFIG.butlerBaseURL}inspect/getBoundPointList`

// 批量更新巡查点频次
export const batchUpdatePointInspectInfoUrl = `${API_CONFIG.butlerBaseURL}inspect/batchUpdatePointInspectInfo`
// 获取项目列表
const queryCommunityListURL = `${API_CONFIG.butlerBaseURL}communityInfo/getAdminCommunityList`
// 批量生成本次巡查任务
const generateInspectLogNowURL = `${API_CONFIG.butlerBaseURL}inspect/generateInspectLogNow`
// 删除(批量删除)巡查点
const batchDeletePointUrl = `${API_CONFIG.butlerBaseURL}inspect/batchDeletePoint`
// 批量删除项目巡查点
const batchDeleteCategoryUrl = `${API_CONFIG.butlerBaseURL}inspect/batchDeleteCategory`
// 巡查执行情况批量删除
const batchDeleteTaskUrl = `${API_CONFIG.butlerBaseURL}inspect/batchDeleteInspectLog`
// 巡查通用内容批量删除
const batchDeleteContentUrl = `${API_CONFIG.butlerBaseURL}inspect/content/delete`


export {
  /** 通用接口 **/
  getCategoryFirstURL,
  getCategorySecondURL,
  getInspectCategoryAllURL,
  getInspectCategoryContentURL,
  /** 巡查通用内容模块 **/
  getContentListURL,
  handleInspectContentOpenCloseURL,
  submitInspectContentURL,
  /** 巡查点分类模块 **/
  getCategoryListURL,
  handleInspectCategoryURL,
  getCategoryDetailURL,
  /** 巡查执行情况模块 **/
  getTaskListURL,
  getTaskDetailURL,
  handleInspectTaskCancelURL,
  exportTaskListURL,
  allDeleteTaskCancelURL,
  /** 巡查点管理模块 **/
  getPointListURL,
  exportPointListURL,
  exportPointQRCodeListURL,
  batchInspectPointOnOffURL,
  handleInspectPointOnOffURL,
  getInspectPointQRCodeURL,
  importMetersURL,
  // getBlockNameUrl,
  getRoomUrl,
  getCategoryIdUrl,
  getBluetoothIdUrl,
  getPatrolUserHouseListURL,
  houseMultiSelectURL,
  getReceiveUserListURL,
  getOrganizeURL,
  getRoleNameListURL,
  getRoleTypeURL,
  savePatrolUserURL,
  deletePatrolUserURL,
  getUserPatrolHouseListURL,
  updatePatrolUserHouseURL,
  getCommunityHouseInfoURL,
  getInspectPointConfigURL,
  saveInspectPointConfigURL,
  getInspectPointConfigByCommunityIdURL,
  getPointInfoUrl,
  getPatrolPointListUrl,
  updatePatrolPointUrl,
  getBoundPointListUrl,
  queryCommunityListURL,
  generateInspectLogNowURL,

  batchDeletePointUrl,
  batchDeleteCategoryUrl,
  batchDeleteTaskUrl,
  batchDeleteContentUrl
}
